export type Procedure = (...args: Array<any>) => void;

export const debounce = (fn: Procedure, ms = 0): Procedure => {
  let timeoutId: NodeJS.Timeout;
  return function(this: any, ...args: Array<any>) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const isBrowser = () => typeof window !== `undefined`;
