import * as React from 'react';
import { WPhotoWrapper } from '../../WPhotoWrapper';
import Link from '../../../../Link/viewer/Link';
import { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import { selectProperComponent, getPropsForLink } from '../../../utils';

const BasicWPhotoSkin: React.FC<BaseWPhotoSkinProps> = ({
  skinsStyle,
  id,
  link,
  imageProps,
  onClickBehavior,
  title,
  onClick,
  onDblClick,
  withOnClickHandler,
}) => {
  const ImageComp = selectProperComponent(onClickBehavior);
  const isPopUp = onClickBehavior === 'zoomMode';
  const linkProps = getPropsForLink({
    onClickBehavior,
    className: skinsStyle.link,
    link,
  });

  return (
    <WPhotoWrapper
      id={id}
      className={skinsStyle.root}
      title={title}
      onClick={onClick}
      onDblClick={onDblClick}
      withOnClickHandler={withOnClickHandler}
    >
      <Link {...linkProps}>
        <ImageComp
          id={`img_${id}`}
          {...imageProps}
          className={skinsStyle.image}
          link={isPopUp && link}
        />
      </Link>
    </WPhotoWrapper>
  );
};

export default BasicWPhotoSkin;
